@import '../../../styles/base/variables';

.wrapper {
  margin-bottom: 5rem;
  padding-bottom: 5rem;
  border-bottom: $gb_section_separator 1px solid;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;

  a {
    &::before {
      display: none;
    }
  }
}

@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';
@import '../../../styles/vendor/icons';

.courseTeaser {
  background: $gb_grey_050;
  margin-bottom: 2.5rem;
  padding: 2.5rem 1.5rem 2rem;
  position: relative;

  @include screen-sm {
    margin-bottom: 1.5rem;
    margin-left: 1.563rem;
    padding: 1.75rem 1.5rem 2rem 4.5rem;
  }

  .courseHeadline {
    max-width: 47rem;

    h4 {
      margin-bottom: 16px;
      @include line-clamp(3);

      @include screen-sm {
        @include line-clamp(2);
      }

      @include screen-md {
        @include line-clamp(1);
      }
    }
  }

  .courseTeaserBodyWrapper {
    display: flex;
    justify-content: space-between;
  }

  .courseTeaserBody {
    border-bottom: $gb_grey_150 1px solid;
    padding-bottom: 1.5rem;
    width: 100%;

    @include screen-md {
      margin-right: 2.5rem;
    }

    .type {
      font-size: 0.75rem;
      margin-bottom: 0.25rem;
    }
  }

  :global {
    .catalogpage,
    .landingpage {
      max-width: 47rem;
    }

    .course-icons {
      height: 3rem;
      position: absolute;
      top: -1.5rem;
      width: 3rem;

      @include screen-sm {
        left: -1.5rem;
        top: 1.25rem;
      }

      &.webinar {
        background-color: $gb_bgcolor_webinar;
      }

      &.elearning {
        background-color: $gb_bgcolor_elearning;
      }

      &.webcast {
        background-color: $gb_bgcolor_webcast;
      }

      &.event {
        background-color: $gb_bgcolor_event;
      }

      &.seminar {
        background-color: $gb_bgcolor_seminar;
      }

      span {
        display: flex;
        justify-content: center;
        position: relative;
      }

      i {
        font-size: 1.5rem;
        line-height: 1.8;
      }
    }

    .expiration-date-wrapper {
      color: $gb_textcolor_expiration_date;
      line-height: 20px;
      text-align: right;
      margin-top: 24px;
      width: 100%;

      @include screen-sm {
        position: relative;
        right: -2rem;
      }

      @include screen-md {
        margin-top: 0;
        right: 2rem;
        position: absolute;
        width: inherit;
      }

      .expiration-date-inner {
        font-weight: $font-weight-bold;
        justify-content: right;

        @include screen-md {
          display: flex;
          flex-direction: row;
        }

        .icon {
          font-size: 16px;
          margin-right: 0.5rem;
        }

        span {
          margin: 0 6px;

          @include screen-md {
            display: flex;
            margin-right: 0;
          }
        }
      }
    }

    h4 {
      text-transform: none;
    }

    .text {
      color: $gb_grey_700;
      max-width: 47rem;
      position: relative;
      @include line-clamp(5);

      @include screen-sm {
        @include line-clamp(3);
      }

      @include screen-md {
        @include line-clamp(2);
      }
    }

    .course-information {
      position: relative;
    }

    .course-information-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .buttonDefaultContainer {
    display: flex;
    margin-top: 24px;

    @include screen-sm {
      margin-top: inherit;
      justify-content: flex-end;
    }

    .buttonDetails {
      min-width: 100%;

      @include screen-sm {
        min-width: 6.25rem;
      }
    }
  }
}

.item {
  display: flex;
  margin-top: 1.5rem;
  position: relative;
  flex-basis: 100%;

  @include screen-sm {
    flex-basis: 50%;
  }

  @include screen-md {
    flex-basis: 33%;
  }

  span b {
    display: flex;

    span {
      min-height: 20px;
    }
  }

  .capacityGreen {
    color: $gb_capacitycolor_green;
    font-weight: bold;
  }

  .capacityOrange {
    color: $gb_capacitycolor_orange;
    font-weight: bold;
  }

  :global {
    .icon {
      margin-right: 0.5rem;
      margin-top: 2px;
      font-size: 1rem;
    }

    .cost-per-person {
      color: var(--gb-brand-primary-dark);
      font-weight: $font-weight-medium;
      position: relative;
      cursor: pointer;
    }

    .item-content {
      display: flex;
      flex-direction: row;
      position: relative;

      span:first-of-type {
        display: flex;
        margin-right: 15px;

        &::after {
          content: '–';
          margin-left: 15px;
        }
      }
    }
  }
}

.popularFlag {
  background: $gb_gdds_cl17;
  border-radius: 0.125rem;
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0.125rem 0.375rem;
  font-size: 0.6875rem;
  line-height: 1rem;
  color: $gb_white;
}
